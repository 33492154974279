import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'
import { ExportData, SectionData, SectionKitchenData, SectionSinkData, SectionType } from "../models/section.model";
import { Inject, Injector } from "@angular/core";
import { GIFControlStatus, IProject, Project } from "../models/project.model";
import * as moment from "moment";
import { SectionService } from "./section.service";
import { DecimalPipe } from "@angular/common";
import { ServerService } from "./server.service";


declare class HookData {
    cursor: {
        x: number;
        y: number;
    }
}

// Bei einem DIN A4 Dokument, das in der Regel das übliche Format in der Geschäftskorrespondenz ist,
// empfiehlt sich ein Abstand von 2,41 cm vom linken Rand und mindestens 0,81 cm vom rechten Rand.
// Soll der Text korrigiert werden, zum Beispiel bei einer wissenschaftlichen Arbeit, sollte der Rand zur rechten Seite hin 5 cm betragen.

// Vom oberen Rand sollte der Abstand 1,69 cm betragen, ebenso vom unteren Rand.

export class PDFService {
    readonly a4Width = 210
    readonly a4Height = 297
    readonly startLeft = 17
    readonly startTop = 17
    readonly maxWidth = this.a4Width - this.startLeft
    readonly maxHeight = this.a4Height - this.startTop
    readonly defaultFont = "Arial"
    readonly defaultFontSize = 9
    readonly logoWidth = 42
    readonly logoHeight = this.logoWidth / 3
    readonly a4Half = this.a4Width / 2

    private pdf
    private currentLeft = this.startLeft
    private currentTop = this.startTop
    private currentPage = 0
    private project
    private sectionServive
    private serverService


    constructor(project: IProject, @Inject(Injector) injector: Injector) {
        this.sectionServive = injector.get(SectionService)
        this.serverService = injector.get(ServerService)
        this.project = new Project(project)
        this.pdf = new jsPDF({
            format: "a4",
            orientation: "portrait",
            unit: "mm"
        })

        this.setDefaultFont()
    }

    private setDefaultFont() {
        this.pdf.setFont(this.defaultFont, "normal", "normal")
        this.pdf.setFontSize(this.defaultFontSize)
    }

    async generatePage() {
        await this.sectionServive.loadDataByProject(this.project.ID)

        const kitchenData = <SectionKitchenData[]>this.sectionServive.getAllData(SectionType.Kochbereich)
        const sinkData = <SectionSinkData[]>this.sectionServive.getAllData(SectionType.Spülbereich)

        this.printOverview(kitchenData, sinkData)
        this.printKitchenPages(kitchenData)
        this.printSinkPages(sinkData)

        this.pdf.save(this.project.getFileName())
    }


    private printKitchenPages(data: SectionKitchenData[]) {
        data.forEach((section, index) => {
            this.addNewPage()
            this.printHeadline("Kochbereich " + (index + 1) + ": " + section.name)
            this.printHeadline("Geräteaufstellung")

            let totalSensibel = 0
            let totalDampf = 0
            let codeSingleValueManuelyChanged = false


            autoTable(this.pdf, {
                theme: "grid",
                startY: this.currentTop,
                margin: this.startLeft,
                headStyles: {
                    fillColor: false,
                    textColor: [0, 0, 0],
                    lineWidth: 0.1,
                    halign: "center",
                    valign: "middle",
                    font: this.defaultFont,
                    fontSize: this.defaultFontSize
                },
                bodyStyles: {
                    fillColor: false,
                    font: this.defaultFont,
                    fontSize: this.defaultFontSize
                },
                footStyles: {
                    fillColor: false,
                    textColor: [0, 0, 0],
                    lineWidth: 0.1,
                    font: this.defaultFont,
                    fontSize: this.defaultFontSize
                },
                columnStyles: {
                    0: { cellWidth: 12 },
                    1: { cellWidth: 8, halign: "right" },
                    2: { cellWidth: "auto" },
                    3: { cellWidth: 10, halign: "right" },
                    4: { cellWidth: 8 },
                    5: { cellWidth: 12, halign: "right" },
                    6: { cellWidth: 15, halign: "right" },
                    7: { cellWidth: 15, halign: "right" },
                    8: { cellWidth: 15, halign: "right" },
                    9: { cellWidth: 15, halign: "right" }
                },
                head: [["Pos", "Stk", "Beschreibung", "kW", "Art", "Code", "Qs\r[W/kW]", "D\r[g/hkW]", "Q\r[W]", "D\r[g/h]"]],
                body: section.getDevices().map((device, index) => {

                    totalDampf += device.sumDampf
                    totalSensibel += device.sumSensibel
                    codeSingleValueManuelyChanged = device.isSingleValueChangedManually() || codeSingleValueManuelyChanged

                    return [
                        device.position,
                        device.amount,
                        device.description,
                        this.formatNumber(device.kw ? device.kw : 0, 1, 1),
                        device.type,
                        device.code + (device.isSingleValueChangedManually() ? "*" : ""),
                        this.formatNumber(device.singelSensibel),
                        this.formatNumber(device.singelDampf),
                        this.formatNumber(device.sumSensibel),
                        this.formatNumber(device.sumDampf)
                    ]
                }),
                foot: [[
                    { content: "Summe", colSpan: 8 },
                    { content: this.formatNumber(totalSensibel), styles: { halign: "right" } },
                    { content: this.formatNumber(totalDampf), styles: { halign: "right" } }
                ]],
                didDrawCell: (data) => {
                    if (!data.cursor) return
                    this.currentTop = data.cursor.y
                }
            })

            this.newRow(3)
            if (codeSingleValueManuelyChanged) {
                this.printVDITableInfo()
            }

            this.newRow(2)
            this.printHeadline("Berechnung des Thermikluftstromes")

            this.printDataList([
                { name1: "Länge:", value1: this.formatNumber(section.lenght) + " mm", name2: "z", value2: section.z.toLocaleString() },
                { name1: "Breite:", value1: this.formatNumber(section.depth) + " mm", name2: "Gleichzeitigkeitsfaktor:", value2: (Math.round(section.gleichzeitigfaktor * 10) / 10).toFixed(1).replace(".", ",") },
                { name1: "Höhe:", value1: this.formatNumber(section.height) + " mm", name2: "Anordnung:", value2: section.anordung == "frei" ? "Frei-stehend" : "Wand-stehend" },
                { name1: "", value1: "", name2: "Ausspülgrad:", value2: section.abspuelgrad.replace(".", ",") }
            ], 40)

            this.newRow(2)

            const abluftNormal = section.calcAbluftNormal()
            const abluftControl = section.calcAbluftKontrolle()

            this.printDataList([
                { name1: "Berechnung des Thermikluftstromes:", value1: this.formatNumber(abluftNormal) + " m³/h", name2: "", value2: "", bold: abluftNormal > abluftControl },
                { name1: "Kontrollrechnung, erforderlicher Abluftstrom zum Schutz vor Kondensation:", value1: this.formatNumber(abluftControl) + " m³/h", name2: "", value2: "", bold: abluftControl > abluftNormal },
            ], 110)
            this.printHeadline("")

        })
    }

    private printSinkPages(data: SectionSinkData[]) {
        data.forEach((section, index) => {
            this.addNewPage()
            this.printHeadline("Spülbereich " + (index + 1) + ": " + section.name)
            this.printSinkStage1(section)
            this.printSinkStage2(section)
            this.printSinkStage3(section)

            this.newRow(2)

            this.pdf.setFont(this.defaultFont, "normal", "bold")
            this.printText("Raumbilanz")
            this.setDefaultFont()

            this.printDataList([
                { name1: "Stufe 1 - Zuluft", value1: this.formatNumber(section.getStep1Zuluft()) + " m³/h", name2: "Stufe 1 - Abluft", value2: this.formatNumber(section.getStep1Abluft()) + " m³/h", bold: section.getStep1Abluft() > section.sumStep2Abluftstrom && section.getStep1Abluft() > section.getStep3Abluft() },
                { name1: "Stufe 2 - Zuluft", value1: this.formatNumber(section.sumStep2Zuluftstrom) + " m³/h", name2: "Stufe 2 - Abluft", value2: this.formatNumber(section.sumStep2Abluftstrom) + " m³/h", bold: section.sumStep2Abluftstrom > section.getStep1Abluft() && section.sumStep2Abluftstrom > section.getStep3Abluft() },
                { name1: "Stufe 3 - Zuluft", value1: this.formatNumber(section.getStep3Zuluft()) + " m³/h", name2: "Stufe 3 - Abluft", value2: this.formatNumber(section.getStep3Abluft()) + " m³/h", bold: section.getStep3Abluft() > section.getStep1Abluft() && section.getStep3Abluft() > section.sumStep2Abluftstrom },
            ])
        })
    }

    private printSinkStage1(section: SectionSinkData) {
        this.newRow(1)
        this.printHeadline("Stufe 1 - Vorplanung, grobe Abschätzung, (Nomogramm Bild 4, Abschnitt 9, VDI 2052)")

        if (section.kuechenGrundflaeche == 0) {
            this.printParagraph("Es wurden keine Daten eingegeben")
            return
        }

        this.printDataList([
            { name1: "Küchengrundfläche:", value1: this.formatNumber(section.kuechenGrundflaeche, 2, 2) + " m²", name2: "Kennwert laut Nomogramm", value2: this.formatNumber(section.kennwertNonogramm, 2, 2) + " m²" },
        ], 40)
    }

    private printSinkStage2(section: SectionSinkData) {
        this.newRow(2)
        this.printHeadline("Stufe 2 - Konkretisierungsplanung, (Tabelle A3, Anhang A, VDI 2052, Normtellerleistung beachten)")

        const devices = section.getDevices()

        if (devices.length == 0) {
            this.printParagraph("Es wurden keine Daten eingegeben")
            return
        }

        let totalZuluft = 0
        let totalAbluft = 0

        autoTable(this.pdf, {
            theme: "grid",
            startY: this.currentTop,
            margin: this.startLeft,
            headStyles: {
                fillColor: false,
                textColor: [0, 0, 0],
                lineWidth: 0.1,
                halign: "center",
                valign: "middle",
                font: this.defaultFont,
                fontSize: this.defaultFontSize
            },
            bodyStyles: {
                fillColor: false,
                font: this.defaultFont,
                fontSize: this.defaultFontSize
            },
            footStyles: {
                fillColor: false,
                textColor: [0, 0, 0],
                lineWidth: 0.1,
                font: this.defaultFont,
                fontSize: this.defaultFontSize
            },
            columnStyles: {
                0: { cellWidth: 12 },
                1: { cellWidth: 12, halign: "right" },
                2: { cellWidth: "auto" },
                3: { cellWidth: 12 },
                4: { cellWidth: 15 },
                5: { cellWidth: 20, halign: "right" },
                6: { cellWidth: 14, halign: "right" },
                7: { cellWidth: 20, halign: "right" },
                8: { cellWidth: 23, halign: "right" }
            },
            head: [["Pos", "Code", "Beschreibung", "WRG", "Abluft-\rstutzen", "Normteller-\rleistung\r[Teller/h]", "Zuluft-\rstrom\r[m³/h]", "anteiliger\rAbluftstrom\r[m³/h]", "erforderlicher Abluftstrom\r[m³/h]"]],
            body: section.getDevices().map((device, index) => {

                totalZuluft += device.zuluftstrom
                totalAbluft += device.abluftstromErforderlich

                return [
                    device.position,
                    device.code ? device.code : "",
                    device.description,
                    device.wrg ? "mit" : "ohne",
                    device.abluftstutzen ? "mit" : "ohne",
                    this.formatNumber(device.normtellerleistung),
                    this.formatNumber(device.zuluftstrom),
                    this.formatNumber(device.abluftstromAnteilig),
                    this.formatNumber(device.abluftstromErforderlich)
                ]
            }),
            foot: [[
                { content: "Summe", colSpan: 6 },
                { content: this.formatNumber(totalZuluft)?.toString(), styles: { halign: "right" } },
                { content: "" },
                { content: this.formatNumber(totalAbluft)?.toString(), styles: { halign: "right" } }
            ]],
            didDrawCell: (data) => {
                if (!data.cursor) return
                this.currentTop = data.cursor.y
            }
        })

        this.newRow(2)
    }

    private printSinkStage3(section: SectionSinkData) {
        this.newRow(2)
        this.printHeadline("Stufe 3 - Feinplanung, Ermittlung des erforderlichen Zuluftvolumenstromes und Abluftvolumenstoms")

        if (section.waermebelastungMaschine == 0) {
            this.printParagraph("Es wurden keine Daten eingegeben")
            return
        }

        this.printDataList([
            { name1: "Allgemeine Berechnung", value1: "", name2: "\"Wärmebelastung des Spülguts\" Berechnung", value2: "", bold: true },
            { name1: "Wärmebelastung der Maschine:", value1: this.formatNumber(section.waermebelastungMaschine, 2, 2) + " kW", name2: "Faktor Wärmeabgabe:", value2: section.faktorWaermeabgabe },
            { name1: "Wärmebelastung des Spülguts:", value1: this.formatNumber(section.waermebelastungSpuelgut, 2, 2) + " kW", name2: "Faktor Abkühlfaktor:", value2: this.formatNumber(Number.parseFloat(section.faktorAbkuehlverhalten), 2, 2) },
            { name1: "Eintrittstemperatur:", value1: this.formatNumber(section.eintrittsTemperatur, 0, 2) + " °C", name2: "Menge des Spülgutes:", value2: this.formatNumber(section.amoutSpuelgut) + " stk" },
            { name1: "Austrittstemperatur:", value1: this.formatNumber(section.austrittsTemperatur, 0, 2) + " °C", name2: "", value2: "" },
        ], 50)
    }

    private addNewPage() {
        this.currentPage++

        if (this.currentPage != 1) {
            this.pdf.addPage("a4", "p")
        }

        this.currentLeft = this.startLeft
        this.currentTop = this.startTop

        this.printWatermark()
        this.setDefaultFont()
        this.printHeader()
        this.newRow(2)
    }

    private printWatermark() {
        if (this.project.controlGIFResult == GIFControlStatus.done) return

        const user = this.serverService.getCurrentUser()
        if (user && user.email.indexOf("@gif-activevent.com") > -1) return

        let text = "NICHT GIF GEPRÜFT"
        let x = 30
        let y = 225

        if (this.project.controlGIFResult == GIFControlStatus.incomplete) {
            text = "UNVOLLSTÄNDIG"
            x = 40
            y = 210
        }

        this.pdf.setTextColor("#d3d3d3")
        this.pdf.setFontSize(70)
        this.pdf.text(text, x, y, {
            angle: 45
        })

        this.setDefaultFont()
        this.pdf.setTextColor("black")
    }


    //Inhalt Übersicht
    private printOverview(kitchenData: SectionData[], sinkData: SectionData[]) {
        this.addNewPage()
        this.printHeadline("Zusammenstellung der erforderlichen Volumenströme")

        let totalSinkA = 0
        let totalSinkZ = 0
        let totalKitchenA = 0

        autoTable(this.pdf, {
            theme: "grid",
            startY: this.currentTop,
            margin: this.startLeft,
            headStyles: {
                fillColor: false,
                textColor: [0, 0, 0],
                lineWidth: 0.1,
                halign: "center",
                valign: "middle",
                font: this.defaultFont,
                fontSize: this.defaultFontSize
            },
            bodyStyles: {
                fillColor: false,
                font: this.defaultFont,
                fontSize: this.defaultFontSize
            },
            footStyles: {
                fillColor: false,
                textColor: [0, 0, 0],
                lineWidth: 0.1,
                font: this.defaultFont,
                fontSize: this.defaultFontSize
            },
            columnStyles: {
                0: { cellWidth: 25 },
                1: { cellWidth: "auto" },
                2: { cellWidth: 25, halign: "right" }
            },
            head: [["Bereich", "Name", "Volumen Abluft [m³/h]"]],
            body: kitchenData.map((val, index) => {
                const abluft = val.calcSectionResult().abluft
                totalKitchenA += abluft

                val.name += val.hasManuellyChangedValues() ? "*" : ""

                return ["Kochbereich " + (index + 1), val.name, this.formatNumber(abluft)]
            }),
            foot: [[{ content: "Summe", colSpan: 2 }, { content: this.formatNumber(totalKitchenA)?.toString(), styles: { halign: "right" } }]],
            didDrawCell: (data) => {
                if (!data.cursor) return
                this.currentTop = data.cursor.y
            }
        })

        this.newRow(3)

        const hasManuellyChangedValues = kitchenData.findIndex(section => section.hasManuellyChangedValues()) >= 0
        if (hasManuellyChangedValues) {
            this.printVDITableInfo()
            this.newRow(1)
        }


        autoTable(this.pdf, {
            theme: "grid",
            startY: this.currentTop,
            margin: this.startLeft,
            headStyles: {
                fillColor: false,
                textColor: [0, 0, 0],
                lineWidth: 0.1,
                halign: "center",
                valign: "middle",
                font: this.defaultFont,
                fontSize: this.defaultFontSize
            },
            bodyStyles: {
                fillColor: false,
                font: this.defaultFont,
                fontSize: this.defaultFontSize
            },
            footStyles: {
                fillColor: false,
                textColor: [0, 0, 0],
                lineWidth: 0.1,
                font: this.defaultFont,
                fontSize: this.defaultFontSize
            },
            columnStyles: {
                0: { cellWidth: 25 },
                1: { cellWidth: "auto" },
                2: { cellWidth: 25, halign: "right" },
                3: { cellWidth: 25, halign: "right" }
            },
            head: [["Bereich", "Name", "Volumen Zuluft [m³/h]", "Volumen Abluft [m³/h]"]],

            body: sinkData.map((val, index) => {
                const data = val.calcSectionResult()
                totalSinkA += data.abluft
                totalSinkZ += data.zuluft

                return ["Spülbereich " + (index + 1), val.name, this.formatNumber(val.calcSectionResult().zuluft), this.formatNumber(val.calcSectionResult().abluft)]
            }),
            foot: [[{ content: "Summe", colSpan: 2 }, { content: this.formatNumber(totalSinkZ)?.toString(), styles: { halign: "right" } }, { content: this.formatNumber(totalSinkA)?.toString(), styles: { halign: "right" } }]],
            didDrawCell: (data) => {
                if (!data.cursor) return
                this.currentTop = data.cursor.y
            }
        })

        this.newRow(3)

        autoTable(this.pdf, {
            theme: "grid",
            startY: this.currentTop,
            margin: this.startLeft,
            bodyStyles: {
                fillColor: false,
                font: this.defaultFont,
                fontSize: this.defaultFontSize,
                textColor: "black",
                fontStyle: "bold"
            },
            columnStyles: {
                0: { cellWidth: "auto" },
                1: { cellWidth: 25, halign: "right" }
            },
            body: [["Gesamtsumme Abluft", this.formatNumber(totalKitchenA + totalSinkA)]],
            didDrawCell: (data) => {
                if (!data.cursor) return
                this.currentTop = data.cursor.y
            }
        })
    }

    //Header
    private printHeader() {
        this.printHeadline("Berechnung von Luftmengen\n\rgemäß VDI Richtlinie 2052 (Ausgabe April 2017)")
        this.printInfoText()
        this.printLogo()
        this.printAdress()
        this.printProjectHeader()
    }

    private printInfoText() {
        this.pdf.setFontSize(6)
        this.printText("Diese Luftmengenberechnung wurde auf Grundlage der uns zur Verfügung\n\rgestellten Informationen nach bestem Wissen erstellt. Gleichwohl bleibt sie\n\rohne Gewähr; mögliche Rechtsansprüche werden ausgeschlossen.")
        this.setDefaultFont()
    }

    private printDataList(list: PdfListEntry[], spaceToText = 30) {
        const col1 = this.startLeft
        const col2 = col1 + spaceToText
        const col3 = this.a4Half
        const col4 = col3 + spaceToText

        list.forEach((entry, index) => {
            if (entry.bold === true) {
                this.pdf.setFont(this.defaultFont, "normal", "bold")
            }

            this.printParagraph(entry.name1, col1, false)
            this.printParagraph(this.formatNumber(entry.value1), col2, false)
            this.printParagraph(entry.name2, col3, false)
            this.printParagraph(this.formatNumber(entry.value2).toString(), col4)

            this.setDefaultFont()
        })
    }

    private formatNumber(value: string | number, minDecimal = 0, maxDecimal = 0) {
        if (typeof value == "string") return value

        const pipe = new DecimalPipe("de")
        const transformed = pipe.transform(value, `1.${minDecimal}-${maxDecimal}`, "de-DE")

        return transformed ? transformed : ""
    }

    private printProjectHeader() {
        this.newRow(2)

        let date = this.project.date ? moment(this.project.date).format("DD.MM.YYYY") : ""

        this.printDataList([
            { name1: "Projektdetials", value1: "", name2: "Planungsgrundlagen", value2: "", bold: true },
            { name1: "Projekt-Name:", value1: this.project.projectName, name2: "Einrichtungsplan:", value2: this.project.plan },
            { name1: "Projekt-Ort:", value1: this.project.city, name2: "Zeichnungsnummer:", value2: this.project.drawingnumber },
            { name1: "Projekt-Nummer:", value1: this.project.projectNumber, name2: "Stand:", value2: this.project.stand },
            { name1: "Sachbearbeiter:", value1: this.project.clerk, name2: "Seite:", value2: this.currentPage.toString() },
            { name1: "Datum:", value1: date, name2: "Bemerkung:", value2: this.project.comment },
        ])
    }

    private printHeadline(text: string, left: number = this.startLeft, newRow = true) {
        this.pdf.setFont(this.defaultFont, "normal", "bold")
        this.pdf.setFontSize(this.defaultFontSize + 2)
        this.printText(text, left, newRow)
        this.setDefaultFont()
    }

    private printText(text: string, left: number = this.startLeft, newRow = true) {
        const textParts = text.split("\n\r")

        textParts.forEach(part => {
            this.printParagraph(part, left, newRow)
        })
    }

    private printParagraph(text: string, left: number = this.startLeft, newRow = true) {
        this.pdf.text(text, left, this.currentTop)

        if (newRow) {
            this.newRow()
        }
    }

    private printLogo() {
        this.pdf.addImage("assets/img/logo.png", "png", this.maxWidth - this.logoWidth, this.startTop - 2, this.logoWidth, this.logoHeight)
    }

    private printAdress() {
        const adressWidth = 35
        this.currentTop = this.startTop

        this.pdf.setFontSize(6)
        this.printText("GIF ActiveVent GmbH\n\rBrühlstraße 7, 79112 Freiburg\n\rTelefon: +49 7664 9302 -0\n\rFax: +49 7664 59997\n\rMail: info@gif-activevent.com", this.maxWidth - this.logoWidth - adressWidth)
        this.setDefaultFont()
    }

    private newRow(count = 1) {
        this.currentTop += this.pdf.getFontSize() * 0.5 * count
    }

    private printVDITableInfo() {
        this.pdf.setTextColor("red")
        this.printParagraph("* Es wurden abweichende Werte zur Tabelle A3 der VDI 2052 verwendet")
        this.pdf.setTextColor("black")
    }
}


export interface PdfListEntry {
    name1: string
    value1: string | number
    name2: string
    value2: string | number
    bold?: boolean
}
