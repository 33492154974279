<div class="wrapper">
    <div class="section header">
        <div class="innerSection">
            <img src="assets/img/logo.png" alt="logo" />
        </div>
    </div>
    <div class="section login">
        <div class="innerSection">
            <div class="left">
                <h1>Herzlich Willkommen bei dem Luftmengenberechnungs-Tool von GIF ActiveVent</h1>
                <p>Berechnen Sie Ihre individuellen Luftmengen für gewerbliche Küchen für VDI 2052.</p>

                <div class="inputs">
                    <mat-form-field appearance="outline">
                        <mat-label>E-Mail</mat-label>
                        <input matInput type="string" name="username" autocomplete="username" [(ngModel)]="login.email">
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Passwort</mat-label>
                        <input matInput type="password" name="password" autocomplete="current-password" [(ngModel)]="login.password">
                    </mat-form-field>
                </div>
                <div class="buttons">
                    <button mat-flat-button color="accent" (click)="onClickRegister()">Registrieren</button>
                    <button mat-flat-button color="accent" (click)="onClickResetPW()">Passwort vergessen</button>
                    <button mat-flat-button color="primary" (click)="onClickLogin()">Einloggen</button>
                </div>
            </div>
            <div class="right">
                <div class="iframeWrapper">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/WwCpaaVRROk?si=kohy1lNfkByja9UN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
    <div class="section dark">
        <div class="innerSection">
            <h2>Vorteile</h2>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
            <br><br>
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
            <br><br>
            Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
        </div>
    </div>

    <div class="section">
        <div class="innerSection">
            <h2>FAQs</h2>
            <cdk-accordion class="example-accordion">
                <cdk-accordion-item *ngFor="let item of items; index as i" #accordionItem="cdkAccordionItem" class="example-accordion-item" role="button" tabindex="0" [attr.id]="'accordion-header-' + i" [attr.aria-expanded]="accordionItem.expanded" [attr.aria-controls]="'accordion-body-' + i">
                    <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
                        {{ item.title }}
                        <span class="example-accordion-item-description">
                            <mat-icon *ngIf="!accordionItem.expanded" fontIcon="expand_more"></mat-icon>
                            <mat-icon *ngIf="accordionItem.expanded" fontIcon="expand_less"></mat-icon>
                        </span>
                    </div>
                    <div class="example-accordion-item-body" role="region" [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + i" [attr.aria-labelledby]="'accordion-header-' + i">
                        {{ item.content }}
                    </div>
                </cdk-accordion-item>
            </cdk-accordion>
        </div>
    </div>
    <app-footer></app-footer>
</div>