<!--<div class="filters" *ngIf="admin">
    <h2>{{ 'title.filterProjects' | translate }}</h2>


     <mat-checkbox [(ngModel)]="showMyProjects">Nur meine Projekte anzeigen</mat-checkbox>

    <div class="buttons">
        <button mat-flat-button color=primary (click)="onClickSearchProjects()">
            <mat-icon>search</mat-icon> {{"button.search" | translate}}
        </button>
    </div>
</div>-->


<h1>
    <span *ngIf="!forUserID">{{ 'title.dashboard' | translate }}</span>
    <span *ngIf="forUserID">{{ 'title.dashboard2' | translate }} {{otherUser?.company}} | {{otherUser?.firstname}} {{otherUser?.lastname}}</span>
    <button mat-flat-button color="primary" (click)="onClickAddNewProject()">
        <mat-icon>add</mat-icon> {{"button.newProject" | translate}}
    </button>
</h1>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="projectNumber">
        <th mat-header-cell *matHeaderCellDef>{{"title.projectnumber" | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.projectNumber}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="projectName">
        <th mat-header-cell *matHeaderCellDef>{{"title.projectname" | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.projectName}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef>{{"title.city" | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.city}}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>{{"title.date" | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.date | date:"dd.MM.yyyy"}}</td>
        <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>
    <ng-container matColumnDef="controlGIF">
        <th mat-header-cell *matHeaderCellDef>{{"label.projectsToControl" | translate}}</th>
        <td mat-cell *matCellDef="let element">{{getGIFControlStatus(element)}}</td>
        <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>
    <ng-container matColumnDef="print">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon" (click)="onClickPrint(element)">
                <mat-icon>print</mat-icon>
            </button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon" [routerLink]="'../projects/' + element.ID">
                <mat-icon>edit</mat-icon>
            </button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <button *ngIf="forUserID === undefined" mat-icon-button class="example-icon" aria-label="Example icon-button with share icon" (click)="onClickDeleteProject(element)">
                <mat-icon>delete</mat-icon>
            </button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>

<div class="buttons">
    <button *ngIf="this.forUserID" class="back" mat-raised-button color="primary" routerLink="../..">
        <mat-icon>arrow_back</mat-icon> {{"button.backToUsers" | translate}}
    </button>
</div>